import { getSkillsManDetailTemplateHtml } from '../../App/TemplateComponents/SkillsManDetail/SkillsManDetailTemplateHtml';

export class SkillsManDetailTemplate {
  rows = {}; // use as result
  userRole = '';

  onclick = (event) => {
    event.preventDefault();
  };

  setCheckedEvent(event) {
    const { setCheck, that } = this;

    [...that.detailViolationsTable.rows].forEach((row) => {
      const input = row.cells[0].firstElementChild;
      input.checked = setCheck;
    });
  }

  constructor(skillsManTemplate, lodashRenderHtml_helper, userRole, userRights) {
    this.skillsManTemplate = skillsManTemplate; // globalVar
    this.lodashRenderHtml_helper = lodashRenderHtml_helper;

    this.format = formatToDisplay_helper;

    this.userRole = userRole;

    this.userRights = userRights;

    this.detailPosCntElement = document.getElementById(
      'skills-man-detail-template-poscount',
    );
    this.allPosesInDetailElement = document.getElementById(
      'skills-man-detail-template-allpositions',
    );

    const detailViolations = document.getElementById(
      'skills-man-detail-template-violations',
    );
    this.detailViolationsTable = detailViolations.querySelector('table');
    this.detailViolationsTableCheckAll = detailViolations.querySelector(
      '[data-elem="checked"]',
    );
    this.detailViolationsTableUnCheckAll = detailViolations.querySelector(
      '[data-elem="unChecked"]',
    );

    this.getTemplateButton = document.getElementById(
      'skills-man-detail-template-setting-window-get-template-button',
    );
    this.addEventListeners();
  }

  addEventListeners() {
    this.detailViolationsTableCheckAll.addEventListener('click', {
      handleEvent: this.setCheckedEvent,
      that: this,
      setCheck: true,
    });
    this.detailViolationsTableUnCheckAll.addEventListener('click', {
      handleEvent: this.setCheckedEvent,
      that: this,
      setCheck: false,
    });
  }

  getGroupedViolations(violationsDescriptionsDesc) {
    const result = {};
    for (const key in violationsDescriptionsDesc) {
      if (violationsDescriptionsDesc.hasOwnProperty(key)) {
        const item = violationsDescriptionsDesc[key];

        if (item.group_num == 0) { // пропускаем не группированные id
          continue;
        }
        if (!result[item.group_num]) {
          result[item.group_num] = [];
        }
        result[item.group_num].push(item);
      }
    }
    return result;
  }

  getDetailViolationsId(violationsDescriptionsDesc) {
    if (!this.detailViolationsTable) {
      return {
        ids: [],
        isAll: true,
      };
    }

    const groupedIds = this.getGroupedViolations(violationsDescriptionsDesc)

    const table = this.detailViolationsTable;
    // const idElements = this.detailViolationsTable.querySelector("input[data-id]");

    return [...table.rows].reduce(
      (accum, row) => {
        const [input, label] = [...row.cells].map(
          (cell) => cell.firstElementChild,
        );

        if (input.checked) {
          const idText = input.dataset.id || -1;
          const idGroup = input.dataset.group || -1;
          const id = parseInt(idText, 10);
          const desc = label.textContent;

          if (idGroup > 0) { // группированное
            for (let item of groupedIds[idGroup]) {
              accum.ids.push({ id: Number(item.id), desc: item.description });
            }
          } else {
            accum.ids.push({ id, desc });
          }
          
        } else {
          accum.isAll = false;
        }

        return accum;
      },
      { ids: [], isAll: true },
    );
  }

  renderHtml(rows) {
    const detailColumnsString = this.getDetailColumnsString();

    lodashRenderHtmlByTemplateHtml_helper(
      rows,
      getSkillsManDetailTemplateHtml(detailColumnsString, undefined, undefined, undefined, rows.hasDetailsRights),
      'section-reports-detail',
    );
  }

  getDetailColumnsString() {
    let detailColumns = [];

    if (this.userRole === 'main_admin') {
      detailColumns = [
        'DTime',
        'DKren',
        'DTangage',
        'DPTOWork',
        'DCanDist',
        'DCanSpeed',
        'DCanGspd',
        'DInputSpeed',
        'DOutputSpeed',
        'DClutchSlip',
        'DClutchSlipSpeed',
        'DClutchWork',
        'DClutchWorkAvg',
        'DClutchWorkAvgLastInclusion',
        'DClutchWorkAvgPeriodSumm',
        'DClutchLastTimeTemperature',
        'DRotatingMoment',
        'DRotatingMomentMax',
        'DRotatingMomentValue', // 09.05.2020 - 19.06.2020
        // 'DAcceleration',
        'DEngineBreakes',
        'DBreakPosition',
        'DHandBreak',
        'DCanEngineTemperature',
        'DCanAccelerate',
        'DCanRmp',
        'DCanLoad',
        'DCanClutch',
        // 'DCruiseControl',
        'DCanGearbox',
        'Dax0l',
        'Dax0r',
        'Dax1l',
        'Dax1r',
        'Dax2l',
        'Dax2r',
        'DSelectedGear',
        // 09.05.2020 'DGearRatioSpdRpm'
      ]; //столбцы детализации
    } else if (this.userRole === 'customer_admin') {
      detailColumns = [
        'DTime',
        'DKren',
        'DTangage',
        'DPTOWork',
        'DCanDist',
        'DCanSpeed',
        'DCanGspd',
        'DInputSpeed',
        'DOutputSpeed',
        'DClutchSlip',
        'DClutchSlipSpeed',
        'DClutchWork',
        'DClutchWorkAvg',
        'DClutchWorkAvgLastInclusion',
        'DClutchWorkAvgPeriodSumm',
        'DClutchLastTimeTemperature',
        'DRotatingMoment',
        'DRotatingMomentMax',
        'DRotatingMomentValue',
        'DEngineBreakes',
        'DBreakPosition',
        'DHandBreak',
        'DCanEngineTemperature',
        'DCanAccelerate',
        'DCanRmp',
        'DCanLoad',
        'DCanClutch',
        'DCanGearbox',
        'Dax0l',
        'Dax0r',
        'Dax1l',
        'Dax1r',
        'Dax2l',
        'Dax2r',
      ]; //столбцы детализации
    } else {
      detailColumns = [
        'DTime',
        'DKren',
        'DTangage',
        'DPTOWork',
        'DCanDist',
        'DCanSpeed',
        'DCanGspd',
        'DInputSpeed',
        'DOutputSpeed',
        'DClutchSlip',
        'DClutchSlipSpeed',
        'DClutchWork',
        'DClutchWorkAvgLastInclusion',
        'DClutchLastTimeTemperature',
        'DRotatingMoment',
        'DRotatingMomentValue',
        'DEngineBreakes',
        'DBreakPosition',
        'DHandBreak',
        'DCanEngineTemperature',
        'DCanAccelerate',
        'DCanRmp',
        'DCanLoad',
        'DCanClutch',
        'DCanGearbox',
        'Dax0l',
        'Dax0r',
        'Dax1l',
        'Dax1r',
        'Dax2l',
        'Dax2r',
      ];
    }

    let detailColumnsString = '';
    let punctuation = '';

    detailColumns.map((str) => {
      detailColumnsString += `${punctuation}'${str}'`;
      punctuation = ', ';
    });
    return detailColumnsString;
  }
}
