<template>
  <my-modal
    v-show="props.show"
    @close="close"
  >
    <template #header>
      <div style="width: 100%; display: flex; justify-content: space-between">
        <button
          class="not-print float-left export-button"
          style="max-height: 40px"
          onclick="html_to_excel_helper.export( this, 'skills-man-detail-template-content', 'Навыки MAN детализация', 'landscape' )"
        >
          <span class="mobile">Экспорт&nbsp;в&nbsp;</span>EXCEL
        </button>

        <h5
          id="violations-details-modal-target"
          class="text-center footer-desc title"
          style="margin: 0 10px"
          v-html="title"
        ></h5>

        <ui-close-button @click="close" />
      </div>
    </template>
    <template #body>
      <div id="violations-details-modal-section-top"></div>
      <div
        v-if="!isReady"
        class="preloader"
      >
        <preloader />
      </div>
      <div
        v-else
        id="violations-details-modal-section"
      ></div>
    </template>
    <template
      v-if="true"
      #footer
    >
      <ui-pagination
        :page-number="pageNumber"
        :pages-length="pagesLength"
        :href="'#violations-details-modal-section-top'"
        @change-value="violationsDetailsRenderHandler"
      />
    </template>
  </my-modal>
</template>

<script setup>
import { inject, nextTick, onMounted, ref, watch } from '@vue/runtime-core';

import MyModal from '../../../Components/MyModal.vue';
import UiPagination from '@/App/Components/UiPagination.vue';
import UiCloseButton from '@/App/Components/Buttons/UiCloseButton.vue';
import Preloader from '@/App/Components/Preloader.vue';

import {
  ajaxPositionsDoneAll,
  url_get_positions,
} from '@/App/use/getElementPosition';
import fetchJson from '@/src/dataRequest/fetch-json';
import SkillsManDetailCalculate from '@/Template/skills_man_template/SkillsManDetailCalculate';
import { violationsDescriptions } from '@/src/dataRequest/mainScript';

import { moreDetailViolationsObject } from '../../../../Leaflet/leaflet_main';
import { getSkillsManDetailTemplateHtml } from '../../../TemplateComponents/SkillsManDetail/SkillsManDetailTemplateHtml';

const userData = inject('userData');

const props = defineProps({
  show: { type: Boolean, default: true },
  isCriticalViolations: { type: Boolean, default: false },
  objId: { type: String, default: '' },
  interval: { type: Array, default: [] },
  violations: { type: Array, default: [] },
  skill: { type: String, default: '' },
  paramsIndex: { type: [String, Number], default: 0 },
  countOfPositionsShownTop: { type: Number, default: 0 },
  countOfPositionsShownBottom: { type: Number, default: 0 },
});

const emit = defineEmits(['close']);

const isReady = ref(false);

const title = ref('');

const offsetPositions = ref({ up: 0, down: 0 });

const paginationArray = ref([]);
const pageNumber = ref(0);
const pagesLength = ref(0);

const projectData = inject('projectData');

const getPagination = () => {
  const { selectedObjId, objectsDetail } = moreDetailViolationsObject.value;

  const { detailViolationRows } = objectsDetail[selectedObjId];

  const rows = detailViolationRows[0];
  const step =
    props.countOfPositionsShownBottom + 1 + props.countOfPositionsShownTop;

  const accum = {
    positionInterval: [
      {
        positionBegin: 0,
        positionEnd: step,
      },
    ],
    positionIndex: 0,
  };

  const { positionInterval } = rows.reduce((acc, obj, index) => {
    if (index > acc.positionInterval[acc.positionIndex].positionEnd) {
      acc.positionInterval.push({
        positionBegin: index,
        positionEnd: index + step,
      });

      acc.positionIndex++;
    }

    return acc;
  }, accum);

  return positionInterval;
};

const getDetailViolationRows = ({
  positionBegin,
  positionEnd,
  detailViolationRows,
}) => {
  const violationRows = [];

  let j = 0;
  for (let i = positionBegin; i < positionEnd; i++) {
    if (!detailViolationRows[0][i]) continue;

    violationRows[j] = detailViolationRows[0][i];

    if (i === +props.paramsIndex) {
      violationRows[j].selected = true;
    }

    j++;
  }

  return violationRows;
};

const getPositionsInterval = (num) => {
  if (num < 0) {
    num = paginationArray.value.findIndex((obj) => {
      return (
        obj.positionBegin <= +props.paramsIndex &&
        obj.positionEnd >= +props.paramsIndex
      );
    });

    const { positionBegin = 0, positionEnd = 0 } = paginationArray.value[num];

    const paramsIndex = +props.paramsIndex;

    const countPositionsUp = positionBegin - paramsIndex;
    const countPositionsDown = positionBegin - paramsIndex;

    if (
      countPositionsUp !== props.countOfPositionsShownTop ||
      countPositionsDown !== props.countOfPositionsShownBottom
    ) {
      offsetPositions.value.up =
        countPositionsUp + props.countOfPositionsShownTop;

      offsetPositions.value.down =
        countPositionsDown + props.countOfPositionsShownBottom;
    }
    pageNumber.value = num;
    return {
      positionBegin: positionBegin - offsetPositions.value.up,
      positionEnd: positionEnd - offsetPositions.value.down,
    };
  }

  pageNumber.value = num;
  return {
    positionBegin:
      paginationArray.value[num].positionBegin - offsetPositions.value.up,
    positionEnd:
      paginationArray.value[num].positionEnd - offsetPositions.value.down,
  };
};

const getDetailColumnsString = () => {
  let detailColumns = [];

  if (projectData.userRole === 'main_admin') {
    detailColumns = [
      'DTime',
      'DKren',
      'DTangage',
      'DPTOWork',
      'DCanDist',
      'DCanSpeed',
      'DCanGspd',
      'DInputSpeed',
      'DOutputSpeed',
      'DClutchSlip',
      'DClutchSlipSpeed',
      'DClutchWork',
      'DClutchWorkAvg',
      'DClutchWorkAvgLastInclusion',
      'DClutchWorkAvgPeriodSumm',
      'DClutchLastTimeTemperature',
      'DRotatingMoment',
      'DRotatingMomentMax',
      'DRotatingMomentValue', // 09.05.2020 - 19.06.2020
      // 'DAcceleration',
      'DEngineBreakes',
      'DBreakPosition',
      'DHandBreak',
      'DCanEngineTemperature',
      'DCanAccelerate',
      'DCanRmp',
      'DCanLoad',
      'DCanClutch',
      // 'DCruiseControl',
      'DCanGearbox',
      'Dax0l',
      'Dax0r',
      'Dax1l',
      'Dax1r',
      'Dax2l',
      'Dax2r',
      'DSelectedGear',
      // 09.05.2020 'DGearRatioSpdRpm'
    ]; //столбцы детализации
  } else if (projectData.userRole === 'customer_admin') {
    detailColumns = [
      'DTime',
      'DKren',
      'DTangage',
      'DPTOWork',
      'DCanDist',
      'DCanSpeed',
      'DCanGspd',
      'DInputSpeed',
      'DOutputSpeed',
      'DClutchSlip',
      'DClutchSlipSpeed',
      'DClutchWork',
      'DClutchWorkAvg',
      'DClutchWorkAvgLastInclusion',
      'DClutchWorkAvgPeriodSumm',
      'DClutchLastTimeTemperature',
      'DRotatingMoment',
      'DRotatingMomentMax',
      'DRotatingMomentValue',
      'DEngineBreakes',
      'DBreakPosition',
      'DHandBreak',
      'DCanEngineTemperature',
      'DCanAccelerate',
      'DCanRmp',
      'DCanLoad',
      'DCanClutch',
      'DCanGearbox',
      'Dax0l',
      'Dax0r',
      'Dax1l',
      'Dax1r',
      'Dax2l',
      'Dax2r',
    ]; //столбцы детализации
  } else {
    detailColumns = [
      'DTime',
      'DKren',
      'DTangage',
      'DPTOWork',
      'DCanDist',
      'DCanSpeed',
      'DCanGspd',
      'DInputSpeed',
      'DOutputSpeed',
      'DClutchSlip',
      'DClutchSlipSpeed',
      'DClutchWork',
      'DClutchWorkAvgLastInclusion',
      'DClutchLastTimeTemperature',
      'DRotatingMoment',
      'DRotatingMomentValue',
      'DEngineBreakes',
      'DBreakPosition',
      'DHandBreak',
      'DCanEngineTemperature',
      'DCanAccelerate',
      'DCanRmp',
      'DCanLoad',
      'DCanClutch',
      'DCanGearbox',
      'Dax0l',
      'Dax0r',
      'Dax1l',
      'Dax1r',
      'Dax2l',
      'Dax2r',
    ];
  }

  let detailColumnsString = '';
  let punctuation = '';

  detailColumns.map((str) => {
    detailColumnsString += `${punctuation}'${str}'`;
    punctuation = ', ';
  });

  return detailColumnsString;
};

const getReportSettings = () => {
  const detailViolationsSetting = {
    ids: [],
    isAll: false,
  };

  const violationsDescriptionsDesc = violationsDescriptions.desc;

  let allPosesInDetailElementChecked = true;
  if (props.violations.length) {
    detailViolationsSetting.ids = props.violations.map((objectData) => ({
      id: +objectData.id,
      desc: objectData.description,
    }));

    detailViolationsSetting.isAll = false;

    allPosesInDetailElementChecked = false;
  } else {
    detailViolationsSetting.ids = violationsDescriptionsDesc.map(
      (objectData) => ({
        id: +objectData.id,
        desc: objectData.description,
      }),
    );

    detailViolationsSetting.isAll = true;
  }

  return {
    violationsDescriptionsDesc,
    detailViolationsSetting,
    morePositionsCnt: 5,
    detailPosCntElementValue: 5,
    allPosesInDetailElementChecked,
  };
};

function filterDetailViolationRows() {
  const { selectedObjId, objectsDetail } = moreDetailViolationsObject.value;

  const { detailViolationRows } = objectsDetail[selectedObjId];

  detailViolationRows[0] = detailViolationRows[0].map((obj) => {
    obj.selected = false;
    return obj;
  });
}

async function violationsDetailsRenderHandler(pageNumber = 0) {
  const { selectedObjId, objectsDetail } = moreDetailViolationsObject.value;

  const {
    violationsFilter,
    detailViolationRows,
    header,
    accordanceColumns,
    violationsDescriptions,
  } = objectsDetail[selectedObjId];

  let { positionBegin = 0, positionEnd = 0 } = getPositionsInterval(pageNumber);

  setTitle({
    violationsFilter: violationsFilter,
    detailViolationRows: detailViolationRows,
    header: header,
    positionBegin,
    positionEnd,
  });

  const violationRows = getDetailViolationRows({
    positionBegin,
    positionEnd,
    detailViolationRows,
  });

  const rows = {
    accordanceColumns: accordanceColumns,
    detailViolationRows: violationRows,
    format: formatToDisplay_helper,
    header: header,
    violationsDescriptions: violationsDescriptions,
    violationsFilter: violationsFilter,
  };

  rows.detailViolationRows = [rows.detailViolationRows];
  rows.getVolationDesc_helper = getVolationDesc_helper;
  rows.hasDetailsRights = hasDetailsRights_helper(userData.senderRights.templates_names_available_arr);

  isReady.value = true;
  await nextTick();

  renderHtml(rows);
  return;
}

function renderHtml(rows) {
  try {
    document.getElementById('violations-details-modal-section').innerHTML = '';
  } catch (error) {
    if (error.message === `Cannot set property 'innerHTML' of null`) {
      console.error(
        'Произошла ошибка с показом детализации. Отсутствует блок с id = violations-details-modal-section',
      );
    }
    return;
  }
  const detailColumnsString = getDetailColumnsString();

  lodashRenderHtmlByTemplateHtml_helper(
    rows,
    getSkillsManDetailTemplateHtml(detailColumnsString, undefined, undefined, undefined, rows.hasDetailsRights),
    'violations-details-modal-section',
  );

  document.getElementById(
    'skillsManDetailsTemplateHtmlExportButton',
  ).innerHTML = '';

  const titleNode = document.getElementById(
    'skillsManDetailsTemplateHtmlTitle',
  );

  titleNode.classList.add('d-none');
  titleNode.innerHTML = title.value;
}

async function requestData() {
  const { selectedObjId, objConf, objectsDetail, queries } =
    moreDetailViolationsObject.value;

  let queryNumber = 0;

  const promises = queries[selectedObjId].map(async ({ options }) => {
    const { body: json, method, headers } = options;

    const body = JSON.parse(json);

    const preparedOptions = {
      method,
      headers,
      body: JSON.stringify({
        objects: body.id,
        timeBegin: body.timeBegin,
        interval: body.interval,
        allColumns: true,
      }),
    };

    const res = await fetchJson(url_get_positions, preparedOptions);

    const percent = Math.floor(
      (queryNumber / queries[selectedObjId].length) * 100,
    );
    title.value = `Загрузка детализации ${queryNumber} из ${queries[selectedObjId].length} (${percent}%)`;

    queryNumber++;

    return res;
  });

  const positions = await Promise.all(promises);

  const piecesPositions = ajaxPositionsDoneAll(positions);

  piecesPositions.objConf = objConf[selectedObjId];

  const {
    violationsDescriptionsDesc,
    detailViolationsSetting,
    morePositionsCnt,
    detailPosCntElementValue,
    allPosesInDetailElementChecked,
  } = getReportSettings();

  const skillsManDetails = SkillsManDetailCalculate({
    REPORT: piecesPositions,
    violationsDescriptionsDesc,
    detailViolationsSetting,
    morePositionsCnt,
    detailPosCntElementValue,
    allPosesInDetailElementChecked,
  });

  const copiedSkillsManDetails = JSON.parse(JSON.stringify(skillsManDetails));
  copiedSkillsManDetails.header = { ...skillsManDetails.header };

  objectsDetail[selectedObjId] = copiedSkillsManDetails;
}

async function displayViolationDetailing() {
  const { selectedObjId, objectsDetail } = moreDetailViolationsObject.value;

  title.value = 'Загрузка детализации';
  // return;
  if (!(selectedObjId in objectsDetail)) {
    await requestData();
    displayViolationDetailing();
    return;
  }

  try {
    filterDetailViolationRows();

    paginationArray.value = getPagination();

    pagesLength.value = paginationArray.value.length;

    violationsDetailsRenderHandler(-1);
  } catch (ex) {
    title.value =
      'Детализация временно не доступна. Повторите запрос либо обновите страницу';
    isReady.value = true;

    console.error(ex.message);
  }
}

function setTitle({
  violationsFilter,
  detailViolationRows,
  header,
  positionBegin,
  positionEnd,
}) {
  const rows = detailViolationRows;

  let timeBegin = 0;
  let timeEnd = 0;

  let startArrIndex = 0;
  let startPosIndex = positionBegin;

  let nextArrIndex = 0;
  let nextPosIndex = positionEnd;

  let filterInfo = '';

  filterInfo = violationsFilter.isAllId
    ? 'выводятся все нарушения'
    : '<span style="color:red">фильтр по нарушениям включен!</span>';

  const conditionSetHeader =
    startArrIndex > -1 &&
    startPosIndex > -1 &&
    nextArrIndex > -1 &&
    nextPosIndex > -1;

  if (!rows[0].length || !conditionSetHeader) {
    title.value = `${header.reportName} ${header.reportPeriod} (${filterInfo})`;
    return;
  }

  if (startArrIndex === 0 && startPosIndex === 0) {
    timeBegin = +props.interval[0];
  } else {
    timeBegin = rows[startArrIndex][startPosIndex]?.DTime
      ? rows[startArrIndex][startPosIndex].DTime
      : rows[0][0].DTime;
  }

  const lastArrLength = rows[rows.length - 1].length;

  if (nextArrIndex === rows.length - 1 && nextPosIndex === lastArrLength) {
    timeEnd = +props.interval[1];
  } else {
    timeEnd = rows[nextArrIndex][nextPosIndex]?.DTime
      ? rows[nextArrIndex][nextPosIndex].DTime
      : rows[rows.length - 1][lastArrLength - 1].DTime;
  }

  title.value = `${header.reportName} c ${formatDateHelper(
    new Date(timeBegin),
    'dd.mm.yyyy hh:nn:ss',
  )} по ${formatDateHelper(
    new Date(timeEnd),
    'dd.mm.yyyy hh:nn:ss',
  )} (${filterInfo})`;
}

function updatePageNumber(num) {
  pageNumber.value = num;
}

function close() {
  emit('close');
  isReady.value = false;
}

watch(
  () => props.show,
  async () => {
    isReady.value = true;
  },
);

onMounted(displayViolationDetailing);
</script>

<style lang="scss" scoped>
.preloader {
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  .export-button {
    font-size: 0.8rem;
    & .mobile {
      display: none;
    }
  }
  .title {
    font-size: 0.8rem;
  }
}
</style>
